import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const SecondPage = (props) => {
 
    const allPosts = props.data.allMarkdownRemark.edges
    console.log(allPosts)
    return (
  <Layout>
    <SEO title="Emailkick Letters" />
    <div className="pt-12">
        <section className="header overflow-hidden">          
            <div className="blurred-class relative max-w-screen  opacity-40">
                <img alt="" className='absolute -top-48 transform scale-100 z-0' src="/images/blurred_1.png" />
                <img alt="" className='absolute md:left-20 md:ml-64 -top-48 z-0 transform md:scale-125' src="/images/blurred_2.png" />
                <img alt="" className='absolute right-32 transform scale-125 z-0' src="/images/blurred_1.png" />
                <img alt="" className='absolute right-5 -top-40 transform md:scale-150 z-0' src="/images/blurred_2.png" />
            </div>
            <div className=" bg-blue-100 bg-opacity-20 z-10 border-b border-gray-100 shadow-lg"> 
                <div className=" max-width-4 mx-auto py-0 z-10">
                    <div className="py-24">
                        <h1 className="text-center font-medium text-4xl z-10">Emailkick letters</h1>
                        <p className="text-center text-lg mt-2 text-gray-400 z-10 ">Crunches of notes prepared to make your customers happy with design</p>
                    </div>
                </div>
            </div>

        </section>

        <section className="py-8 min-h-full"> 

            <div className="grid grid-cols-3 gap-8 max-w-6xl mx-auto mt-4 ">
                {
                    allPosts.map((post) => (
                        <Link to={post.node.frontmatter.slug} className="transform duration-100 hover:-translate-y-2 flex flex-col bg-white pb-8 rounded-xl shadow-sm border border-gray-200">
                            <div className="h-64 border border-gray-100">
                                <img src="/emailkick-splash.png" className="h-full w-full object-cover" />
                            </div>
                            <div className="md:w-full mt-3 px-4">
                                <h2 className="text-lg font-medium tracking-normal ">{post.node.frontmatter.title}</h2>
                                <p className="text-sm text-gray-600 mt-1 font-normal" >No-code application crossover party 🎉</p>
                            </div>
                        </Link>
                    ))
                }
                
            </div>

        </section>



    </div>
  </Layout>
)}


export const pageQuery  = graphql`
{
  allMarkdownRemark(filter: {frontmatter: {type: {eq: "letters"}}}) {
    edges {
      node {
        frontmatter {
          date
          title
          slug
          section
          sectionId
          pageCode
          nextLink
          next
          featImg
        }
      }
    }
  }
}
`

export default SecondPage
